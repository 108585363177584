import { Form, Input, Button, message, Collapse } from "antd";
import React, { useState } from "react";
import { Auth } from "../../firebase";
const { Panel } = Collapse;

const PasswordChangeForm = () => {

    const onFinish = (values) => {
        // console.log("Success:", values);
        var user = Auth().currentUser;
        var recentEmail = Auth().currentUser.email;
    
        var credentials = Auth.EmailAuthProvider.credential(
          recentEmail,
          values.passwordOld
        );
    
        if (values.email !== recentEmail) {
          setLoading(true)
          user
            .reauthenticateWithCredential(credentials)
            .then(function () {
                user
                  .updatePassword(values.passwordNew)
                  .then(function () {
                    setLoading(false)
                    message.success('Password changed successfully')
                  })
                  .catch(function (error) {
                    // An error happened.
                    console.log(error)
                    message.error(error.message);
                    setLoading(false)
                  });
            })
            .catch(function (error) {
              // An error happened.
              console.log(error);
              setLoading(false);
              message.error(error.message);
            });
        }
    
        form.setFieldsValue({
          passwordOld: "",
          passwordNew:""
        });
      };

      const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm();

    return (
      <div>
        <div
          style={{ maxWidth: "960px", margin: "24px auto", padding: "0 16px" }}
        >
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header={
                <div style={{ fontWeight: "bold" }}>Change your Password</div>
              }
              key="1"
            >
              <Form
                size="large"
                layout="vertical"
                name="settingForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item
                  name="passwordOld"
                  label="Old Password"
                  rules={[
                    {
                      required: true,
                      message:
                        "Old Password is required to change new password",
                    },
                  ]}
                >
                  <Input.Password allowClear placeholder="Old Password" />
                </Form.Item>

                <Form.Item
                  name="passwordNew"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password",
                    },
                  ]}
                >
                  <Input.Password allowClear placeholder="New Password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
}

export default PasswordChangeForm
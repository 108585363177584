import { Menu } from 'antd';
import {
  DashboardOutlined,
  ProfileOutlined,
  EditFilled,
  SettingOutlined,

} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

const { SubMenu } = Menu;



const SideMenu = () => {
  const [ menukeys, setMenuKeys ] = useState()
  const history = useHistory();

  useEffect(() => {
    setMenuKeys(history.location.pathname);
  }, [history.location.pathname]);

    // console.log(history.location.pathname)

    return (
      <div>
        <Menu
          style={{
            width: "18vw",
            height: "100vh",
            position: "fixed",
            left: "0",
            zIndex: "10",
          }}
          defaultSelectedKeys={["/"]}
          selectedKeys={[menukeys]}
          defaultOpenKeys={["posts", "tags"]}
          mode="inline"
          theme="dark"
          onClick={(e) => history.push(e.key)}
        >
          <Menu.Item key="/" icon={<DashboardOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="/profile" icon={<ProfileOutlined />}>
            Profile Information
          </Menu.Item>
          <SubMenu key="posts" icon={<EditFilled />} title="Posts">
            <Menu.Item key="/create-new">Write New</Menu.Item>
            <Menu.Item key="/all-posts">All Posts</Menu.Item>
            {/* <Menu.Item key="/all-drafts">Drafts</Menu.Item> */}
          </SubMenu>
          {/* <SubMenu key="tags" icon={<SettingOutlined />} title="Tags">
            <Menu.Item key="/new-tags">Create New</Menu.Item>
            <Menu.Item key="/all-tags">Create and Edit</Menu.Item>
          </SubMenu> */}
        </Menu>
      </div>
    );
}

export default SideMenu
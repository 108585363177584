import { useState } from "react";
import { Form, Input, Button, Collapse, message } from "antd";
import { Auth, Db } from "../../firebase";

const { Panel } = Collapse;

const NameEmailChangeSection = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("Success:", values);
    var user = Auth().currentUser;
    var UID = Auth().currentUser.uid;
    var recentEmail = Auth().currentUser.email;
    var recentDisplayName = Auth().currentUser.displayName;

    // console.log(user, recentEmail);

    if (values.displayName !== recentDisplayName) {
      setLoading(true);
      Db.ref("users/" + UID).update({
        name: values.displayName,
      });
      user
        .updateProfile({
          displayName: values.displayName,
        })
        .then(function () {
          message.success("Name updated");
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }

    var credentials = Auth.EmailAuthProvider.credential(
      recentEmail,
      values.password
    );

    if (values.email !== recentEmail) {
      setLoading(true);
      user
        .reauthenticateWithCredential(credentials)
        .then(function () {
          user
            .updateEmail(values.email)
            .then(function () {
              message.success("email updated");
              setLoading(false);
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
            });
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
          setLoading(false);
          message.error(error.message);
        });
    }

    form.setFieldsValue({
      password: "",
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <div
        style={{ maxWidth: "960px", margin: "24px auto", padding: "0 16px" }}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel
            header={
              <div style={{ fontWeight: "bold" }}>
                Change your Name and Email
              </div>
            }
            key="1"
          >
            <Form
              autoComplete="false"
              size="large"
              name="nameEmailForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              form={form}
            >
              <Form.Item
                label="Your Full Name"
                initialValue={Auth().currentUser.displayName}
                name="displayName"
                rules={[
                  { required: true, message: "Please input your FullName" },
                ]}
              >
                <Input
                  className="auth_form"
                  allowClear
                  type="name"
                  placeholder="Full Name"
                />
              </Form.Item>
              <Form.Item
                label="Your Email"
                name="email"
                initialValue={Auth().currentUser.email}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  className="auth_form"
                  allowClear
                  type="email"
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                label="Enter your password to update email and name"
                name="password"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter your password to update full name and email",
                  },
                ]}
              >
                <Input.Password allowClear placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default NameEmailChangeSection;

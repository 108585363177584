
import Layout from "../Layout"
import AdditionalInformation from "../ProfileSection/AdditionalInformation";
import NameEmailChangeSection from "../ProfileSection/NameEmailSection"
import PasswordChangeForm from "../ProfileSection/PasswordChange";
import PictureSectionCover from "../ProfileSection/PictureSectionCover";
import PictureSectionDp from "../ProfileSection/PictureSectionDp";






const ProfileRoute = () => {


    

    return (
      <div>
        <Layout>
          <PictureSectionDp />
          <PictureSectionCover />
          <NameEmailChangeSection />
          <PasswordChangeForm />
          <AdditionalInformation />
          <br/><br />
        </Layout>
      </div>
    );
}

export default ProfileRoute;
import { useEffect, useState } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { Db } from "../../firebase";

const { Option } = Select


const TitleAndTimeSectionForm = (props) => {


    const [loading, setLoading] = useState(false);
    const [ formLoading, setFormLoading ] = useState(true)
    const [ formValues, setFormValues] = useState({})

    const onFinish = (values) => {
      setLoading(true);
      Db.ref("posts/")
        .child(props.id)
        .update({
          ...values,
          lastEdited: Date.now(),
          slug: values.dishTitle.replace(/\s+/g, "-").toLowerCase(),
        });
      // console.log(snapshot.key);
      setLoading(false);
      message.success("Updated")};
    
      const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
      };


      useEffect(() => {
        Db.ref("posts/")
        .child(props.id).on("value", (snap) => {
            if(snap.val() !== null) {
                setFormValues(snap.val())
                setFormLoading(false)
            } else{ 
                setFormLoading(false);
            }
        })
      },[props.id])

// console.log(formValues , formLoading)
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {formLoading ? (
            "..."
          ) : (
            <Form
              autoComplete="off"
              layout="vertical"
              size="large"
              style={{ width: "100%" }}
              name="post-edit-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Dish Title"
                name="dishTitle"
                initialValue={formValues.dishTitle}
                rules={[
                  {
                    required: true,
                    message: "Please enter the dish title",
                  },
                  {
                    pattern: new RegExp("^[a-zA-Z0-9_ ]+$"),
                    message: "No special Characters allowed in dish title",
                  },
                ]}
              >
                <Input placeholder="Enter the dish title" />
              </Form.Item>
              <Form.Item
                label="Time required to prepare the dish"
                name="time"
                initialValue={formValues.time}
              >
                <Input placeholder="Write the time required to prepare the dishin hours or minutes" />
              </Form.Item>

              <Form.Item
                label="Select a category | You can select multiple"
                name="category"
                initialValue={formValues.category}
                rules={[
                  {
                    required: true,
                    message: "Please select atleast a category",
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Please select a category">
                  <Option value="veg">Pure Veg</Option>
                  <Option value="vegDairy">
                    Veg [ Contains Dairy Products ]
                  </Option>
                  <Option value="nonVeg">Non Veg</Option>
                  <Option value="Starters">Starter / Appetizer</Option>
                  <Option value="MainCourse">Main Course</Option>
                  <Option value="Desserts">Desserts</Option>
                  <Option value="Drinks">Juice and Smoothies</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    float: "right",
                    borderRadius: "4px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.027) 0px -5px 10.6px, rgba(0, 0, 0, 0.08) 0px 4.5px 10px",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    );
}

export default TitleAndTimeSectionForm
import { useState, useEffect } from "react";
import { Alert, Button, Form, Input, message } from "antd";
import { Db } from "../../firebase";

const Ingrediants = (props) => {
  const [ings, setIngsVal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    // console.log("Success:", values);
    Db.ref("posts/")
      .child(props.id + "/ingrediants")
      .push(values)
      .then(() => {
        form.resetFields();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    Db.ref("posts/")
      .child(props.id + "/ingrediants")
      .on("value", (snap) => {
        if (snap.val() !== null && snap.val() !== undefined) {
          setIngsVal(snap.val());
          setFormLoading(false);
          // console.log(snap.val())
        } else {
          setIngsVal([]);
          setFormLoading(false);
        }
      });
  }, []);

  // console.log(ings);

  return (
    <div>
      {formLoading ? (
        "..."
      ) : (
        <div>
          {ings.length !== 0
            ? Object.keys(ings)
                .map((key) => ({ ...ings[key], id: key }))
                .map((e) => (
                  <Alert
                    key={e.id}
                    message={e.ingrediant}
                    type="success"
                    showIcon
                    action={
                      <Button
                        size="small"
                        type="text"
                        onClick={() =>
                          Db.ref("posts/" + props.id)
                            .child("/ingrediants/" + e.id)
                            .remove()
                            .then(() => message.success("deleted"))
                            .catch((e) => console.log(e))
                        }
                      >
                        delete
                      </Button>
                    }
                  />
                ))
            : "No ingrediants added"}
        </div>
      )}

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        size="large"
        style={{ width: "100%" }}
        name="ingrediants-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Ingredient Name"
          name="ingrediant"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please enter the Ingredient name",
            }
            // {
            //   pattern: new RegExp("^[a-zA-Z0-9\-\/,\_ ]+$"),
            //   message: "No special Characters allowed in Ingredient name",
            // },
          ]}
        >
          <Input placeholder="Enter the Ingredient name" />
        </Form.Item>
        <Button
          style={{
            borderRadius: "4px",
            float: "right",
            boxShadow:
              "rgba(0, 0, 0, 0.027) 0px -5px 10.6px, rgba(0, 0, 0, 0.08) 0px 4.5px 10px",
          }}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Add
        </Button>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default Ingrediants;

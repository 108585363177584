import { useState , useEffect } from "react";
import { Collapse, Upload, message, Image , Button, Skeleton  } from "antd";
import ImgCrop from "antd-img-crop";
import { Db, storage, Auth } from "../../firebase";
import { UploadOutlined } from "@ant-design/icons";

const { Panel } = Collapse;




function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt10M;
}





const PictureSectionCover = () => {

  var UID = Auth().currentUser.uid;
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState("");
  const [iid, setiid] = useState("");
 
 


  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };


  const onPreview = async (file) => {
    let src = file.url;
    // console.log(src)
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const customUpload = ({ onError, onSuccess, file }) => {
    const imgFile = storage.ref().child(`users/${UID}/cover.${file.name.replace(/[-+()\s]/g, '')}`);
    imgFile
      .put(file)
      .then(() => {
        imgFile.getDownloadURL().then((url) => {
          var imgRef = Db.ref(`users/${UID}/cover`).push();
          var key = imgRef.key;
          var data = {
            uid: key,
            fileUid: file.name.replace(/[-+()\s]/g, ''),
            fileUrl: url,
          };
          Db.ref(`users/${UID}/images-cover/${key}`)
            .update(data)
            // .then(() => console.log("uploaded"));
          
        });
        onSuccess("ok");
        // console.log(file.uid);
      })
      .catch((e) => onError(e));
  };

  const handleRemove = (props) => {
    // console.log(props);

    const imgFile = storage.ref().child(`users/${UID}/cover.${fileId}`);
    if (imgFile) {
      imgFile
        .delete()
        .then(() => {
          // console.log(`deleted Successfully`);
          Db.ref(`users/${UID}/images-cover/${iid}`).remove();
        })
        .catch((e) => console.log(e));
    }
  };

   useEffect(() => {
    Db.ref("users/" + UID + "/images-cover").on("value", (snap) => {
      // console.log(snap.val())
      if (snap.val()) {
        const arrImage = Object.values(snap.val());
        setFileList([
          {
            uid: "-1",
            name: `cover`,
            status: "done",
            url: `${arrImage[0].fileUrl}`,
          },
        ]);
        setFileId(arrImage[0].fileUid);
        setiid(arrImage[0].uid);
      } else {
        setFileList([]);
      }
    });
  }, [UID]);

// console.log(fileList);

    return (
      <div>
        <div
          style={{ maxWidth: "960px", margin: "24px auto", padding: "0 16px" }}
        >
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header={
                <div style={{ fontWeight: "bold" }}>Change Cover Photo</div>
              }
              key="1"
            >
              {fileList !== undefined &&
              fileList.length !== 0 &&
              fileList[0].url !== null &&
              fileList[0].url !== undefined ? (
                <Image src={fileList[0].url} alt="picture" width="100%" style={{width:"100%"}} />
              ) : (
                <Skeleton />
              )}
              <ImgCrop grid aspect={1.77}>
                <Upload
                  listType="picture"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  beforeUpload={beforeUpload}
                  customRequest={customUpload}
                  onRemove={handleRemove}
                >
                  {fileList.length < 1 && <Button icon={<UploadOutlined />}>Upload Cover Picture</Button>}
                  
                </Upload>
              </ImgCrop>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
}


export default PictureSectionCover
import Layout from "../Layout"

const NewTagsRoute = () => {
    return (
      <div>
        <Layout>New Tags Route</Layout>
      </div>
    );
}

export default NewTagsRoute



const YoutubeEmbeddSection = () => {
    return(
        <div>
                Here Goes the youtube embed link form
        </div>
    )
}

export default YoutubeEmbeddSection
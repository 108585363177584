import { Upload , message } from "antd"
import { UploadOutlined } from "@ant-design/icons";
import { Db, storage } from "../../firebase"
import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react/cjs/react.development";



const ImageGallery = (props) => {

    const [fileList, setFileList] = useState([]);
    // const [path, setPath] = useState("");
    // const [iid, setiid] = useState("");
    const [loading, setLoading] = useState(false);
    const [formloading, setFormLoading] = useState(true);

    const handleRemove = (e) => {
        // console.log(e)
      const imgFile = e.path !== undefined ? storage.ref().child(e.path) : "";
     
      if (imgFile !== "" ) {
        imgFile
          .delete()
          .then(() => {
            console.log(`deleted Successfully`);
            Db.ref(`posts/${props.id}/images/${e.name}`).remove();
            message.success("Deleted");
          })
          .catch((e) => {
            message.error("Some error occured");
            console.log(e);
          });
      } else {
        //   console.log('removed')
      }
    };


      function beforeUpload(file) {
        const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG file! Please delete and reupload an JPEG or PNG image", 3, () =>{ 
            //   console.log(file)              
          setFileList(fileList.filter((item) => item.uid !== file.uid));
            });
          
        }
        const isLt10M = file.size / 1024 / 1024 < 20;
        if (!isLt10M) {
          message.error("Image must smaller than 20MB! ", 3);
          setFileList(fileList.filter((item) => item.uid !== file.uid));
        }
        return isJpgOrPng && isLt10M;
      }


      const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };

      const onPreview = async (file) => {
        let src = file.url;
        // console.log(src)
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };


      const customUpload = ({ onError, onSuccess, file }) => {
        var imageFile = file;
        // console.log("originalFile instanceof Blob", imageFile instanceof Blob); 
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    
        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        imageCompression(imageFile, options)
          .then(function (compressedFile) {           
            
           const imgFile = storage
              .ref()
              .child(`posts/${props.id}/${file.uid}.${file.name.replace(/[-+()\s]/g, '')}`);
            imgFile
              .put(compressedFile)
              .then(() => {
                imgFile.getDownloadURL().then((url) => {
                  var imgRef = Db.ref(
                    `props/${props.id}/images/`
                  ).push();
                  var key = imgRef.key;
                  var data = {
                    uid: key,
                    fileUid: file.name.replace(/[-+()\s]/g, ''),
                    fileUrl: url,
                    path: `posts/${props.id}/${file.uid}.${file.name.replace(/[-+()\s]/g, '')}`,
                  };
                  Db.ref(`posts/${props.id}/images/${key}`)
                    .update(data)
                    .then(() => {
                      console.log("uploaded");
                    });
                });
                onSuccess("ok");
                // console.log(file.uid);
              })
              .catch((e) => onError(e));
          })
          .catch(function (error) {
            console.log(error.message);
          });
    
      };


      useEffect(() => {
        const ref = Db.ref("posts/" + props.id + "/images");
        const listener = ref.on("value", (snap) => {
          //   console.log(snap.val())
          if (snap.val()) {
            const arrImage = Object.values(snap.val());
            // console.log(arrImage)

            setFileList(
              arrImage.map((e, idx) => ({
                ...e,
                uid: idx,
                name: e.uid,
                status: "done",
                url: e.fileUrl,
                path: e.path,
                iid: e.iid,
              }))
            );
            // setPath(arrImage[0].path);
            // setiid(arrImage[0].uid);
          } else {
            setFileList([]);
          }
        });
      }, [])

    

    return (
      <div>
        <h3>Upload your images here [ max 6 allowed ]</h3>
        <br />
        <Upload
          listType="picture-card"
          fileList={fileList}
          className="upload-list-inline"
          onChange={onChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
          customRequest={customUpload}
          onRemove={handleRemove}
        >
          {fileList.length < 6 ? (
              <div>
            <UploadOutlined />
                  <br />
                  Upload 
              </div>
          ) : (
            ""
          )}
        </Upload>
      </div>
    );
}

export default ImageGallery;
import ReactQuill from "react-quill";
import { Button, message } from "antd";
import { useEffect, useState } from "react/cjs/react.development";
import { Db } from "../../firebase";

const RichTextEditor = (props) => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    Db.ref("posts/")
      .child(props.id)
      .on("value", (snap) => {
        if(snap.val()) {
          if (snap.val().text !== null && snap.val().text !== undefined) {
            setText(snap.val().text);
            setFormLoading(false);
          } else {
            setFormLoading(false);
          }
        }
        
      });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    Db.ref("posts/")
      .child(props.id)
      .update({
        text: text,
      })
      .then(() => {
        message.success("Submitted Successfully")
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error("Some Error Occured")
        console.log(e);
      });
  };

  return (
    <div style={{height:"600px"}}>
      
     
        {formLoading ? "..." : <>
        <ReactQuill
          style={{ width: "100%", height: "400px" }}
          value={text}
          onChange={(e) => setText(e)}
        />

        <Button
          style={{
            marginTop: "64px",
            float: "right",
            borderRadius: "4px",
            boxShadow:
              "rgba(0, 0, 0, 0.027) 0px -5px 10.6px, rgba(0, 0, 0, 0.08) 0px 4.5px 10px",
          }}
          type="primary"
          size="large"
          loading={loading}
          onClick={onSubmit}
        >
          Submit
        </Button>
        </>}
 
    </div>
  );
};

export default RichTextEditor;

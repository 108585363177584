import Layout from "../Layout"

const AllTagsRoute = () => {
    return (
      <div>
        <Layout>All Tags Route</Layout>
      </div>
    );
}

export default AllTagsRoute
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"
import 'firebase/storage'
import "firebase/analytics";
  
  
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyBro6ZAnT-qA6Qep4iK1Nhhy1S9VDqC1-g",
    authDomain: "myfoodbook-1ef76.firebaseapp.com",
    databaseURL: "https://myfoodbook-1ef76.firebaseio.com",
    projectId: "myfoodbook-1ef76",
    storageBucket: "myfoodbook-1ef76.appspot.com",
    appId: "1:68434412645:web:899cb3ad7ed4cce8ca728c",
    // messagingSenderId: "68434412645",
    // measurementId: "G-83YTWB4XH6"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();





export const Auth = firebase.auth;
export const Db = firebase.database();
export const storage = firebase.storage();
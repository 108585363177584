import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useState, useEffect } from 'react'
import { AppContext } from "./context";
import { Auth } from './firebase';
import Dashboard from "./components/routes/DashBoard";
import LoginScreen from "./components/routes/LoginScreen";
import './App.css';
import ProfileRoute from "./components/routes/Profile";
import CreatePostRoute from "./components/routes/CreatePost";
import AllPostsRoute from "./components/routes/AllPosts";
import AllDraftsRoute from "./components/routes/AllDrafts";
import NewTagsRoute from "./components/routes/NewTags";
import AllTagsRoute from "./components/routes/AllTags";



const CompleteApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route exact path="/profile">
          <ProfileRoute />
        </Route>
        <Route exact path="/create-new">
          <CreatePostRoute />
        </Route>
        <Route exact path="/all-posts">
          <AllPostsRoute />
        </Route>
        <Route exact path="/all-drafts">
          <AllDraftsRoute />
        </Route>
        <Route exact path="/new-tags">
          <NewTagsRoute />
        </Route>
        <Route exact path="/all-tags">
          <AllTagsRoute />
        </Route>
      </Switch>
    </Router>
  );
}




const  App = () => {

  const [ loading , setLoading ] = useState(true)
  const [existUser, setUser] = useState([]);


  useEffect(() => {
 
   Auth().onAuthStateChanged((user) => {
     if (user != null) {
       setUser(user);
       setLoading(false)
     } else {
       setLoading(false)
     }
   })
  }, []);




  return (
    <div>
     <AppContext.Provider value={{ existUser }}>
      {loading ? (
        "Loading..."
      ) : existUser.length !== 0 ? (
        <CompleteApp />
      ) : (
        <LoginScreen />
      )}
    </AppContext.Provider>
    </div>
  );
}

export default App;

// import { Button } from "antd/lib/radio"
import Layout from "../Layout";


const Dashboard = () => {

   
    return (
      <div>
        <div>
          <Layout>
            this is DashBoard
          </Layout>
        </div>
      </div>
    );
}

export default Dashboard
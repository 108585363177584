const FooterMenu = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            background: "#001529",
            color: "#f0f0f0",
            padding: "15px",
            position: "fixed",
            bottom: "0",
            zIndex: "1",
            marginLeft:"18vw"
          }}
        >
          <small>All Rights Reserved. MyFoodBook &copy; 2021</small>
        </div>
      </div>
    );
}

export default FooterMenu
import React, { useContext } from "react";
import { AppContext } from "../../context";
import FooterMenu from "./footer";
import HeaderFile from "./header";
import SideMenu from "./SideMenu";


const Layout = ({ children }) => {

  const value = useContext(AppContext)
  const displayName = value.existUser.displayName

  return (
    <>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <HeaderFile name={displayName}/>
          <main style={{marginLeft:"18vw", marginTop:"4rem"}}>{children}</main>
          <FooterMenu />
        </div>
      </div>
    </>
  );
};

export default Layout;

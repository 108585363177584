import { Table, Popconfirm, Button, message, Switch, Tag } from "antd";
import { Db, storage } from "../../firebase"
import Layout from "../Layout";
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";

const DeleteProductBtn = props => {
  // console.log(props.props.catImgStoragePath)
  return (
    <Popconfirm
      title="Are you sure delete this product"
      onConfirm={() => {
        Db.ref(`posts/${props.props.key}`).remove();
        let ref = storage.ref().child(`posts/${props.props.key}`);
        if(ref !== undefined && ref !== null) {
          ref
            .listAll()
            .then((dir) => {
              // console.log(dir);
              dir.items.forEach((fileRef) => {
                fileRef.delete();
                // console.log(fileRef.location);
              });
            })
            .catch((error) => {
              console.log(error);
            })
            .then(() => {
              console.log("deleted successfully");
              message.info("Deleted");
            })
            .catch((e) => console.log(e));
        }
        
      }}
      onCancel={() => message.info("Cancelled")}
      okText="Yes"
      cancelText="No"
    >
      <Button>Delete</Button>
    </Popconfirm>
  );
};

const DisableEnableProductBtn = props => {
  return (
    <Switch
      props={props}
      onChange={() =>
        {
          Db.ref(`posts/${props.props.key}/`).update({
          draft: !props.props.draft
        })
          props.props.draft ? message.info('Post published') : message.info('Post saved as draft')
        }
      }
      checked={props.props.draft}
    />
  );
};


const AllPostsRoute = () => {
  const [productData, setProductData] = useState([]);
  let history = useHistory();

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      width:"70px"
    },
    {
      title: "Post title",
      dataIndex: "dishTitle",
      width:"250px"
    },
    {
      title: "Time Required",
      dataIndex: "time",
      ellipsis: true,
    },
   
    {
      title: "Edit Product",
      dataIndex: "",
      key: "z",
      render: props => (
        <div>

          <Button onClick={() => {
            history.push(`/create-new?id=${props.key}`);
          }}>Edit post</Button>
        </div>
      )
    },
    {
      title: "Disable",
      dataIndex: "",
      key: "y",
      render: props => (
        <div>
          <DisableEnableProductBtn props={props} />
        </div>
      )
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "x",
      render: props => (
        <div>
          <DeleteProductBtn props={props} />
        </div>
      )
    }
  ];


  useEffect(() => {
    Db.ref("posts").on("value", snap => {
      if (snap.val()) {
        setProductData(
          Object.keys(snap.val()).map((x, idx) => ({
            index: idx + 1,
            key: x,
            ...snap.val()[x]
          }))
        );
        // console.log(snap.val())
      } else {
        setProductData([]);
      }
    });
  }, [])

 
  return (
    <div>
      <Layout>
        <div
          style={{
            maxWidth: "960px",
            margin: "24px auto",
            padding: "0 16px",
          }}
        >
          <h4 style={{ textAlign: "center" }}>View All Posts</h4>
          <Table
            dataSource={productData}
            columns={columns}
            pagination={{
              pageSizeOptions: ["5", "10", "15"],
              showSizeChanger: true,
            }}
          />
        </div>
      </Layout>
    </div>
  );
};

export default AllPostsRoute;

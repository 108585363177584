import { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { Auth, Db } from "../../firebase";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const TitleAndTimeSection = () => {
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  let history = useHistory();

  const onFinish = (values) => {
    setLoading(true);
    Db.ref("posts/")
      .push({
        ...values,
        draft: true,
        postedTime: Date.now(),
        slug: values.dishTitle.replace(/\s+/g, "-").toLowerCase(),
        user: Auth().currentUser.uid,
        youtube:""
      })
      .then((snapshot) => {
        Db.ref("posts/").child(snapshot.key).update({ id: snapshot.key });
        // console.log(snapshot.key);
        setLoading(false);
        setSubmit(true);
        history.push(`/create-new?id=${snapshot.key}`);
      });
    setTimeout(() => setSubmit(false), 1500);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let mounted = true;
    return function cleanup() {
        // eslint-disable-next-line
        mounted = false
    }
  },[])

  return (
    <div>
      <div
        style={{
          maxWidth: "960px",
          height: "80vh",
          margin: "24px auto",
          padding: "0 16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Form
            autoComplete="off"
            layout="vertical"
            size="large"
            style={{ width: "100%" }}
            name="post-initialize-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Dish Title"
              name="dishTitle"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: "Please enter the dish title",
                },
                {
                  pattern: new RegExp("^[a-zA-Z0-9_ ]+$"),
                  message: "No special Characters allowed in dish title",
                },
              ]}
            >
              <Input placeholder="Enter the dish title" />
            </Form.Item>
            <Form.Item
              label="Time required to prepare the dish"
              name="time"
              initialValue=""
            >
              <Input placeholder="Write the time required to prepare the dishin hours or minutes" />
            </Form.Item>

            <Form.Item
              label="Select a category | You can select multiple"
              name="category"
              initialValue={[]}
              rules={[
                {
                  required: true,
                  message: "Please select atleast a category",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select a category"
                
              >
                <Option value="veg">Pure Veg</Option>
                <Option value="vegDairy">Veg [ Contains Dairy Products ]</Option>
                <Option value="nonVeg">Non Veg</Option>
                <Option value="Starters">Starter / Appetizer</Option>
                <Option value="MainCourse">Main Course</Option>
                <Option value="Desserts">Desserts</Option>
                <Option value="Drinks">Juice and Smoothies</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                style={{
                  borderRadius: "4px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.027) 0px -5px 10.6px, rgba(0, 0, 0, 0.08) 0px 4.5px 10px",
                }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
              &nbsp; {submit ? <b style={{ color: "green" }}>submitted</b> : ""}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TitleAndTimeSection;

import { useEffect, useState } from "react";
import { Form, Input, Button, Collapse, message } from "antd";
import { Db, Auth } from "../../firebase";
const { Panel } = Collapse;


const AdditionalInformation = () => {
  var UID = Auth().currentUser.uid;
  const [actionBtnVals, setActionBtnVals] = useState({});
  // const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  
  const [formLoading, setFormLoading] = useState(true);

  const onFinish = (values) => {
    setLoading(true);
    Db.ref("users/" + UID)
      .update(values)
      .then(() => {
        setLoading(false);
        message.success("Submitted")
       
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    Db.ref("users/" + UID).on("value", (snap) => {
      // console.log(snap.val())
      setFormLoading(false);
      if (snap.val() !== null) {
        setActionBtnVals(snap.val());
      } else {
        setFormLoading(false);
      }
    });
  }, [UID]);

  return (
    <div>
      <div
        style={{ maxWidth: "960px", margin: "24px auto", padding: "0 16px" }}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel
            header={
              <div style={{ fontWeight: "bold" }}>Additional Information</div>
            }
            key="1"
          >
            {formLoading ? (
              "..."
            ) : (
              <Form
                
                size="large"
                layout="vertical"
                name="social-form"
                autoComplete="false"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="What is your occupation ?"
                  name="occupation"
                  initialValue={actionBtnVals.occupation}
                >
                  <Input placeholder="What is your occupation ?" />
                </Form.Item>

                <Form.Item
                  label="About Me"
                  name="aboutMe"
                  initialValue={actionBtnVals.aboutMe}
                >
                  <Input.TextArea placeholder="Write few lines about yourself." />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{
                    //   position: "absolute",
                    //   right: "0",
                      borderRadius: "4px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.027) 0px -5px 10.6px, rgba(0, 0, 0, 0.08) 0px 4.5px 10px",
                    }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                  
                </Form.Item>
              </Form>
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default AdditionalInformation;

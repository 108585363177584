import { Form, Input, Button, message, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Auth, Db } from "../../firebase";

const LoginForm = () => {
  const [loadingState, setLoadingState] = useState(false);
  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    setLoadingState(true);

    Auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((user) => {
        //    console.log('login successful', user.user.uid)
        setLoadingState(false);
      })
      .catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        // console.log(errorCode, errorMessage);
        message.error(errorMessage);
        setLoadingState(false);
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your Email",
          },
          {
            type: "email",
            message: "Please enter a valid email",
          },
        ]}
      >
        <Input className="auth_form"
          allowClear
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password className="auth_form" 
          allowClear
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loadingState}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

const SignUpForm = () => {
  const [loadingState, setLoadingState] = useState(false);

  const onFinish = (values) => {
    // console.log('Received values of signup form: ', values);
    setLoadingState(true);

    Auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((user) => {
        // console.log(user.user.uid)

        Auth()
          .currentUser.updateProfile({
            displayName: values.fullName,
          })
          .then()
          .catch(function (error) {
            console.log(error);
          });

        Db.ref("users/" + user.user.uid).set({
          name: values.fullName,
          email: values.email,
          aboutMe: "",
          occupation: "",
        });
       
        // console.log("login successful");
        setLoadingState(false);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        message.error(errorMessage);
        setLoadingState(false);
      });
  };

  return (
    <Form
      name="normal_signup"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="fullName"
        rules={[
          {
            required: true,
            message: "Please input your Full Name",
          },
        ]}
      >
        <Input
          className="auth_form"
          allowClear
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Full Name"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter your Email",
          },
          {
            type: "email",
            message: "Please enter a valid email",
          },
        ]}
      >
        <Input
          className="auth_form"
          allowClear
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="email"
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password
          className="auth_form"
          allowClear
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item
        name="check_privacy_term"
        rules={[
          {
            required: true,
            message: "Please Accept the Terms & Conditions and Privacy Policy",
          },
        ]}
        valuePropName="checked"
      >
        <Checkbox style={{ color: "#fff" }}>
          Please Accept <a href="/terms">Terms & Conditions</a> and{" "}
          <a href="/privacy">Privacy Policy</a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loadingState}
        >
          SIGN UP
        </Button>
      </Form.Item>
    </Form>
  );
};




const LoginScreen = () => {
    const [loginState, setLoginState] = React.useState("true");

    return (
      <div style={{ padding: "8px"  }} className="loginScreen">
        <div
          style={{
            maxWidth: "416px",
            width: "100%",
            margin: "0 auto",
            padding: "8px",
            borderRadius: "8px",
            border:"1px solid #f8f8ff"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "normal",
              width: "100%",
            //   height: "85vh",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ margin: "16px 8px" }}
                onClick={() => setLoginState(true)}
              >
                LOGIN
              </Button>
              <Button
                style={{ margin: "16px 8px" }}
                onClick={() => setLoginState(false)}
              >
                SIGN UP
              </Button>
            </div>
            {loginState ? <LoginForm /> : <SignUpForm />}
          </div>
        </div>
      </div>
    )
}

export default LoginScreen
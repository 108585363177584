import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { Tooltip , Button, Popconfirm, message } from "antd"

import { Auth } from "../../firebase";


const HeaderFile = (props) => {
    

    const confirm = (e) => {
        Auth()
          .signOut()
          .then(() => {
            // Sign-out successful.
            window.location.reload();
          })
          .catch((error) => {
            // An error happened.
            console.log(error);
          });
        // message.success('Click on Yes');
      }
      
      const cancel = (e) => {
        // console.log(e);
        message.error('cancelled');
      }

   

    return (
      <div>
        <div
          style={{
            width: "100%",
            background: "#001529",
            color: "#f0f0f0",
            padding: "10px",
            position:"fixed",
            top:"0", zIndex:"9"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft:"18vw"
            }}
          >
            <div>
              Hello <UserOutlined /> <strong>{props.name}</strong>
            </div>
            <div style={{ marginRight: "16px", cursor: "pointer" }}>
              <Popconfirm
                title="Are you sure you want to logout ?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
                placement="bottomRight"
              >
                <Tooltip title="logout">
                  <Button  type="primary">
                    <LogoutOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    );
}

export default HeaderFile
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import TitleAndTimeSection from "../PostsSection/CreateTitleAndTime";
import RichTextEditor from "../PostsSection/RichTextEditor";
import ImageGallery from "../PostsSection/ImageGallery"
import TitleAndTimeSectionForm from "../PostsSection/TitleTimeSection";
import Ingrediants from "../PostsSection/Ingrediants";
import { Button, Collapse, message } from "antd"
import { Db } from "../../firebase";
import { useState } from "react/cjs/react.development";
import YoutubeEmbeddSection from "../PostsSection/YoutubeEmbedd";
const { Panel } = Collapse;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreatePostRoute = () => {
  const [ loading, setLoading ] = useState(false)
  let query = useQuery();

  const ID = query.get("id");

 

  return (
    <div>
      <Layout>
        {ID !== null && ID !== "" ? (
          <div>
            <div
              style={{
                maxWidth: "960px",
                margin: "24px auto",
                padding: "0 16px",
              }}
            >
              <Collapse expandIconPosition={`right`} defaultActiveKey={["1", "2", "3", "4", "5"]}>
                <Panel
                  header={
                    <span style={{ fontWeight: "bold" }}>Introduction</span>
                  }
                  key="1"
                >
                  <TitleAndTimeSectionForm id={ID} />
                </Panel>
                <Panel
                  header={<span style={{ fontWeight: "bold" }}>Ingredient</span>}
                  key="2"
                >
                  <Ingrediants id={ID} />
                </Panel>
                <Panel
                  header={
                    <span style={{ fontWeight: "bold" }}>Write Recipe</span>
                  }
                  key="3"
                >
                  <RichTextEditor id={ID} />
                </Panel>

                <Panel
                  header={
                    <span style={{ fontWeight: "bold" }}>
                      Youtube Embedd Link
                    </span>
                  }
                  key="5"
                >
                  <YoutubeEmbeddSection id={ID} />
                </Panel>

                <Panel
                  header={<span style={{ fontWeight: "bold" }}>Add Images</span>}
                  key="4"
                >
                  <ImageGallery id={ID} />
                </Panel>
              </Collapse>
              <br />
              <Button
                loading={loading}
                style={{ width: "100%", height: "50px", fontWeight: "bold" }}
                onClick={() => {
                  setLoading(true);
                  Db.ref("posts/" + ID)
                    .update({ draft: false })
                    .then(() => {
                      message.success("Your post has been published");
                      setLoading(false);
                    })
                    .catch((e) => {
                      setLoading(false);
                      console.log(e);
                    });
                }}
                type="primary"
              >
                PUBLISH POST
              </Button>
            </div>
            <br /> <br /> <br />
            <br /> <br /> <br />
          </div>
        ) : (
          <TitleAndTimeSection />
        )}
      </Layout>
    </div>
  );
};

export default CreatePostRoute;
